import React, { useEffect, useState } from 'react';
import './App.scss';
import { useLocation } from 'react-router-dom';
import { Comment } from 'react-loader-spinner';
import { getContext, askOpenAI } from './services';

const App: React.FC = () => {
  const location = useLocation();
  const [inputText, setInputText] = useState<string>('');
  const [response, setResponse] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const q = searchParams.get('q');
    if (q) {
      setInputText(decodeURIComponent(q));
    }
  }, [location]);

  const handleSubmit = async () => {
    const currentURL = window.location.href;
    const urlWithoutParams = currentURL.split('?')[0];
    window.history.replaceState(null, document.title, urlWithoutParams + `?q=${encodeURIComponent(inputText)}`)
    setLoading(true);
    if (inputText.trim()) {
      const context = await getContext(inputText);
      const response = await askOpenAI(inputText, context);
      setResponse(response);
      setLoading(false);
    } else {
      setResponse(`Please provide a prompt.`);
    }
  };

  return (
    <div className="app">
      <textarea
        className="input-box"
        placeholder="Type here..."
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
      />
      <button className="submit-button" onClick={handleSubmit}>
        Submit
      </button>
      {
        loading ? <div className="loading-spinner">
          <Comment
            visible={true}
            height="80"
            width="80"
            ariaLabel="comment-loading"
            wrapperStyle={{}}
            wrapperClass="comment-wrapper"
            color="#fff"
            backgroundColor="#F4442E"
          />
        </div> : <div className="response">{response}</div>
      }
    </div>
  );
};

export default App;
