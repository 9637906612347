import axios from 'axios';

export const getContext = async (query: string): Promise<string> => {
    try {
        const encodedQuery = encodeURIComponent(query);
        const apiKey = `4L2PM7FzP83B81Q9bTlgk4oM9GWGc4Ri3HSK5ybp`;
        console.log(apiKey);
        const response = await axios.get(
            `https://e137lli3v6.execute-api.us-east-1.amazonaws.com/prod/askmybook/ask?q=${encodedQuery}`,
            {
                headers: {
                    'accept': 'application/json',
                    'x-api-key': `${apiKey}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching assistant data:', error);
        throw error;
    }
};

export const askOpenAI = async (query: string, context: string): Promise<string> => {
    let prompt = "Use the information below the dashed line to answer the question below. Use the same professional voice and tone as the person who wrote the information.:\n\n"
    prompt += `Question: ${query}\n\n`
    prompt += "---\n\n"
    prompt += context

    try {
        const apiKey = `eCLAX7gL0S1jdTnl9OOt18pBUNtVSpIs5LDwUFhx`;
        console.log(apiKey);
        const response = await axios.post(
            `https://d3fytu4e3b.execute-api.us-east-1.amazonaws.com/prod/ask`,
            { "prompt": prompt },
            {
                headers: {
                    'accept': 'application/json',
                    'x-api-key': `${apiKey}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching assistant data:', error);
        throw error;
    }
};
